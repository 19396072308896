<template>
  <div>
    <header-admin-pages
            title="Новости"
            :counter="getNews.length"
            :button="headerButton"/>

    <div class="row">
      <div class="search-field">
        <label for="key">
          Поиск:
        </label>
        <input type="text" id="key" class="input blue" v-model="search.key">
      </div>

      <div class="search-field">
        <label for="key">
          Дата от:
        </label>
        <input type="date" id="date-start" class="input blue" v-model="search.date_start">
      </div>

      <div class="search-field">
        <label for="key">
          Дата до:
        </label>
        <input type="date" id="date-end" class="input blue" v-model="search.date_end">
      </div>

      <div class="search-field">
        <label for="rubric">
          Рубрика:
        </label>
        <select name="rubric" id="rubric" class="input blue" v-model="search.rubric">
          <option :value="null">
            -
          </option>
          <option :value="rubric.id" v-for="(rubric, index) in getRubrics" :key="`rubric${index}`">
            {{ rubric.title }}
          </option>
        </select>
      </div>

      <div class="search-field">
        <label for="systemTagsList">
          Раздел:
        </label>
        <select name="systemTagsList" id="systemTagsList" class="input blue" v-model="search.system_tags_list">
          <option :value="null">
            -
          </option>
          <option v-for="(systemTag, index) in system_tags" :value="systemTag.code" :key="`systemTag${index}`">
            {{ systemTag.title }}
          </option>
        </select>
      </div>

      <button class="btn-blue" @click="setSearch">
        Поиск
      </button>
    </div>

    <table>
      <tr>
        <td>Заголовок</td>
        <td>Описание</td>
        <td>Дата</td>
        <td>Признак главной новости</td>
        <td class="drop-icon"></td>
      </tr>
      <tr v-for="news in getNews" :key="`news${news.id}`">
        <td>{{ news.title }}</td>
        <td>{{ news.description }}</td>
        <td>{{ getFormattedDate(news.date_time) }}</td>
        <td>{{ news.important ? 'Да' : 'Нет'}}</td>
        <td class="drop-icon">
          <drop-list @clickItem="clickItem"
                     :id-item="news.id"
                     :edit-link="`/admin/news/${news.id}/edit`"/>
        </td>
      </tr>
    </table>

    <pagination class="pagination__block"
                :per-page="getAdminNewsPagination.limit"
                :current="getAdminNewsPagination.offset / getAdminNewsPagination.limit + 1"
                :total="getAdminNewsPagination.total"
                @page-changed="getPage"/>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'
import formatDate from '@/mixins/formatDate'
import Pagination from '@/components/Pagination'

export default {
  name: 'News',

  data () {
    return {
      system_tags: [
        {
          title: 'Все новости',
          code: 'all'
        },
        {
          title: 'Актуальное',
          code: 'actual'
        },
        {
          title: 'Общественные палаты регионов',
          code: 'public_chamber_news'
        },
        {
          title: 'Общественные наблюдательные комиссии',
          code: 'public_monitoring_commissions'
        },
        {
          title: 'Общественная экспертиза',
          code: 'public_examination'
        },
        {
          title: 'Общественная советы',
          code: 'public_councils'
        },
        {
          title: 'Проекты',
          code: 'projects'
        },
        {
          title: 'Молния',
          code: 'lightning'
        }
      ],
      search: {
        key: null,
        date_start: null,
        date_end: null,
        rubric: null,
        system_tags_list: null
      },
      headerButton: {
        title: 'Добавить новость',
        link: '/admin/news/create'
      }
    }
  },

  created () {
    this.$store.dispatch('getNews')
    this.$store.dispatch('getRubrics')
  },

  methods: {
    deleteNews (id) {
      this.$store.dispatch('deleteNews', id)
    },

    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить новость?')
        if (consent) {
          this.$store.dispatch('deleteNews', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailMembers', value.id)
      }
    },

    setSearch () {
      this.$store.commit('setAdminNewsSearch', this.search)
      this.$store.dispatch('getNews')
    },

    getPage (page) {
      this.$store.commit('setAdminNewsPaginationOffset', (page - 1) * this.getAdminNewsPagination.limit)
      this.$store.dispatch('getNews')
    }
  },

  computed: {
    getRubrics () {
      return this.$store.getters.getRubrics
    },

    getNews () {
      return this.$store.getters.getNews
    },

    getAdminNewsPagination () {
      return this.$store.getters.getAdminNewsPagination
    }
  },

  components: {
    Pagination,
    DropList,
    HeaderAdminPages
  },

  mixins: [formatDate]
}
</script>

<style scoped lang="scss">
  .pagination__block {
    margin-top: 1rem;
  }

  .row {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    .search-field {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: .5rem;

      label {
        text-align: left;
        font-size: .812rem;
        line-height: 154%;
        color: #5E686C;
      }
    }

    button {
      height: 3rem;
      min-width: 5rem;
      width: 10rem;
    }
  }

  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
