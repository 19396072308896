export default {
  computed: {
    locale () {
      return this.$store.getters.GET_LANG ? 'ru-ru' : 'en-US'
    }
  },
  methods: {
    getMonth (date) {
      const month = date.toLocaleString(this.locale, {
        month: 'long'
      })

      return month
    },

    getTimeTwoDigit (value) {
      return value < 10 ? '0' + value : value
    },

    getFormattedDate (dateString, withTime, notWithSeconds) {
      const date = new Date(dateString)
      const time = `${this.getTimeTwoDigit(date.getHours())}:${this.getTimeTwoDigit(date.getMinutes())}`
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const engMonths = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
      ]

      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }

      if (withTime) {
        format.hour = format.minute = 'numeric'

        if (!notWithSeconds) {
          format.second = 'numeric'
        }
      }
      return `${date.getDate()} ${this.$store.getters.GET_LANG ? months[date.getMonth()] : engMonths[date.getMonth()]}${withTime ? ' ' + time : ''}, ${date.getFullYear()}`
      // return date.toLocaleString(this.locale, format)
    }
  }
}
